<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <a href="javascript:;">หน้าหลัก</a>
      </li>
      <li class="breadcrumb-item active">นายก อบจ. (ข้อมูลรายตำบล)</li>
    </ol>
    <h1 class="page-header">บันทึกข้อมูล นายก อบจ. (ข้อมูลรายตำบล)</h1>
    <panel title="บันทึกข้อมูล นายก อบจ. (ข้อมูลรายตำบล)" noButton="true" bodyClass="no-padding">
      <div class="card border-1">
        <div class="card-body p-0">
          <h4 class="card-title mt-3 ms-3">
            กรุณาเลือกเงื่อนไขการรายงานผลข้อมูล
            <small class="text-red-darker f-w-700">({{ totalRecords.toLocaleString() }} รายการ)</small>
          </h4>
          <b-form inline class="ps-3 pt-2">
            <b-form-select class="mb-2 me-sm-2 mb-sm-0" v-model="acl.prov_confirm" :options="acl.prov_confirms" style="height: 30px; width: 150px" @change="changeConfirm()"></b-form-select>
            <b-form-select class="mb-2 me-sm-2 mb-sm-0" v-model="acl.province_id" :options="acl.provinces" style="height: 30px; width: 140px" :disabled="true"></b-form-select>
            <b-form-select class="mb-2 me-sm-2 mb-sm-0" v-model="acl.district_id" :options="acl.districts" style="height: 30px; width: 140px" @change="changeDistrict()" :disabled="currentUser.group_id == 3 ? true : false"></b-form-select>
            <b-form-select class="mb-2 me-sm-2 mb-sm-0" v-model="acl.election_area_id" :options="acl.election_areas" style="height: 30px; width: 140px" @change="changeElectionArea()" :disabled="currentUser.group_id == 3 ? true : false"></b-form-select>
            <b-form-select class="mb-2 me-sm-2 mb-sm-0" v-model="acl.sub_district_id" :options="acl.sub_districts" style="height: 30px; width: 140px" @change="changeSubDistrict()"></b-form-select>
          </b-form>
          <div class="table-responsive table-bordered">
            <vue-good-table
              ref="my-table"
              mode="remote"
              :columns="columns"
              :rows="rows"
              :line-numbers="true"
              :select-options="{ enabled: false }"
              :search-options="{ enabled: true, skipDiacritics: true, placeholder: 'ค้นหาข้อมูล' }"
              :pagination-options="{ enabled: true }"
              :sort-options="{ enabled: false }"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              @on-search="onSearch"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'unit_status'">
                  {{ props.row.prov_no_send ? props.row.prov_no_send : 0 }}
                  / {{ props.row.prov_send ? props.row.prov_send : 0 }} / {{ props.row.prov_confirm ? props.row.prov_confirm : 0 }}
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
            </vue-good-table>
          </div>
          <h4>หมายเหตุ</h4>
          <CardDescription></CardDescription>
        </div>
      </div>
    </panel>
    <!-- end panel -->
  </div>
</template>

<script>
import _ from 'lodash'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import CardDescription from '../share/CardDescription.vue'

export default {
  name: 'PresidentSubDistrict',
  components: {
    VueGoodTable,
    CardDescription,
  },
  data() {
    return {
      acl: {
        prov_confirm: '',
        prov_confirms: [
          { value: '', text: '* ทุกสถานะ' },
          { value: '0', text: '1 ยังไม่ส่งผลคะแนน' },
          { value: '1', text: '2 ส่งผลคะแนนแล้ว (รอยืนยัน)' },
          { value: '2', text: '3 ยืนยันผลคะแนนแล้ว' },
        ],
        province_id: 32,
        provinces: [{ value: 32, text: '32 จ.สุรินทร์' }],
        district_id: '',
        districts: [
          { value: '', text: '* ทุกอำเภอ' },
          { value: '3201', text: '01 อ.เมืองสุรินทร์' },
          { value: '3202', text: '02 อ.ชุมพลบุรี' },
          { value: '3203', text: '03 อ.ท่าตูม' },
          { value: '3204', text: '04 อ.จอมพระ' },
          { value: '3205', text: '05 อ.ปราสาท' },
          { value: '3206', text: '06 อ.กาบเชิง' },
          { value: '3207', text: '07 อ.รัตนบุรี' },
          { value: '3208', text: '08 อ.สนม' },
          { value: '3209', text: '09 อ.ศีขรภูมิ' },
          { value: '3210', text: '10 อ.สังขะ' },
          { value: '3211', text: '11 อ.ลำดวน' },
          { value: '3212', text: '12 อ.สำโรงทาบ' },
          { value: '3213', text: '13 อ.บัวเชด' },
          { value: '3214', text: '14 อ.พนมดงรัก' },
          { value: '3215', text: '15 อ.ศรีณรงค์' },
          { value: '3216', text: '16 อ.เขวาสินรินทร์' },
          { value: '3217', text: '17 อ.โนนนารายณ์' },
        ],
        election_area_id: '',
        election_areas: [],
        sub_district_id: '',
        sub_districts: [],
      },
      election_unit_id: null,
      isLoading: false,
      totalRecords: 0,
      filter: {
        t: 'get_good_table_sub_district',
        searchTerm: '',
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: 'เขตเลือกตั้งที่',
          field: 'election_area_name',
        },
        {
          label: 'ตำบล',
          field: 'sub_district_name',
        },
        {
          label: 'หน่วยนับ',
          field: 'unit_count',
          width: '75px',
          tdClass: 'text-center',
        },
        {
          label: 'ม / ส / ย',
          field: 'unit_status',
          width: '80px',
          tdClass: 'text-center',
        },
        {
          label: 'ข้อ 1.',
          field: 'prov_elector',
          type: 'number',
        },
        {
          label: 'ข้อ 2.',
          field: 'prov_comein',
          type: 'number',
        },
        {
          label: 'ข้อ 3.',
          field: 'prov_receive',
          type: 'number',
        },
        {
          label: 'ข้อ 4.',
          field: 'prov_use',
          type: 'number',
        },
        {
          label: 'ข้อ 5.',
          field: 'prov_good',
          type: 'number',
        },
        {
          label: 'ข้อ 6.',
          field: 'prov_fail',
          type: 'number',
        },
        {
          label: 'ข้อ 7.',
          field: 'prov_no_vote',
          type: 'number',
        },
        {
          label: 'ข้อ 8.',
          field: 'prov_remain',
          type: 'number',
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentUser() {
      let user = this.$store.state.auth.user
      user.group_id = 3
      user.district_id = 3201
      user.election_area_id = 1
      return user
    },
  },
  mounted() {
    this.getRecords()
  },
  methods: {
    updateData() {
      this.$nextTick(() => {
        this.getRecords()
        this.$bvModal.hide('edit-modal')
      })
    },
    showEdit(idx) {
      this.$nextTick(() => {
        this.election_unit_id = idx
        this.$bvModal.show('edit-modal')
      })
    },
    changeConfirm() {
      this.filter.page = 1
      this.getRecords()
    },
    changeDistrict() {
      this.filter.page = 1
      this.acl.election_areas = []
      this.acl.election_area_id = ''
      this.acl.sub_districts = []
      this.acl.sub_district_id = ''
      if (this.acl.district_id) {
        let url = `election_areas?t=get_list&district_id=${this.acl.district_id}`
        this.axios.get(url).then((res) => (this.acl.election_areas = res.data))
      }
      this.getRecords()
    },
    changeElectionArea() {
      this.filter.page = 1
      this.acl.sub_districts = []
      this.acl.sub_district_id = ''
      if (this.acl.election_area_id) {
        let url = `area/sub_districts?t=get_list&election_area_id=${this.acl.election_area_id}`
        this.axios.get(url).then((res) => (this.acl.sub_districts = res.data))
      }
      this.getRecords()
    },
    changeSubDistrict() {
      this.filter.page = 1
      this.getRecords()
    },
    getRecords() {
      this.isLoading = true
      this.filter = Object.assign({}, this.filter, {
        prov_confirm: this.acl.prov_confirm,
        province_id: this.acl.province_id,
        district_id: this.acl.district_id,
        election_area_id: this.acl.election_area_id,
        sub_district_id: this.acl.sub_district_id,
      })
      this.axios.get('election_units', { params: this.filter }).then((res) => {
        this.$nextTick(() => {
          this.rows = res.data.data
          this.totalRecords = res.data.total
          // localStorage.setItem('quest_family_filter', JSON.stringify(this.filter))
          this.isLoading = false
        })
      })
    },
    updateParams(newProps) {
      this.filter = Object.assign({}, this.filter, newProps)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.getRecords()
    },
    onPerPageChange(params) {
      this.updateParams({ page: 1, per_page: params.currentPerPage })
      this.getRecords()
    },
    onSearch: _.debounce(function (params) {
      params.page = 1
      this.updateParams(params)
      this.getRecords()
      return false
    }, 250),
  },
}
</script>

<style></style>
